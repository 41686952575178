import React from 'react';
import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Text,
    StyledLink,
    SubText
} from './DonateElements';

export default function DonateElement() {
        
    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/">Donut Dash</Icon>
                    <FormContent>
                        <Text>
                            <StyledLink href='//donate.stripe.com/6oEaH847dgap8hifYY' target="_blank" rel="noreferrer">Make a Donation</StyledLink>
                        </Text>
                        <SubText>Donate Dash is officially a 501(c)(3) non-profit!</SubText>
                        <SubText>You can make a tax deductible donation by clicking on the link above</SubText>
                        <SubText>To request a receipt of your donation please contact info@pghdonutdash.org</SubText>
                    </FormContent>
                </FormWrap>
            </Container>
        </>
    );
};
