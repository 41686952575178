import React, { useState, useEffect } from "react";
import axios from 'axios';

import {
    Container,
    Icon,
    SubIcon,
    Text,
    TableContainer,
	Table,
	TableRow,
	TableCell,
	TableHeaderCell,
	TableHeader,
	TableBody,
    InputWrapper,
    Input,
    Button,
    FormContent,
    StyledLink,
    Select
} from './GroupsElements';

// Get the access token from local storage
const token = localStorage.getItem('token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
};

function GroupsElement() {

	const date = new Date();
    const year = date.getFullYear();

    const [groups, setGroups] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [groupPocEmail, setGroupPocEmail] = useState("");
    const [groupPocPhone, setGroupPocPhone] = useState("");

    // Fetches the group data from the server
    const getGroups = async () => {
        try {
            const allGroups = await axios.get(`${process.env.REACT_APP_API_URL}/groups`, config);
            setGroups(allGroups.data);
        } catch (err) {
            console.log(err);
        }
    };

    // Fetch group data immediately on page load
    useEffect(() => {
        getGroups();
    }, []);

    // Checks if the group name is unique and if the email and phone number are valid
    const assertValidGroup = () => {
        // Check if group name is unique
        const isGroupNameUnique = !groups.some(group => group.groupName === groupName);

        if (!isGroupNameUnique) {
            alert("Group name must be unique");
            return false;
        }

        if (groupName && groupPocEmail && groupPocPhone) {
            const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
            const phonePattern = /^([0-9]{3}[-\s]?)?[0-9]{3}[-\s]?[0-9]{4}$/;

            // Check if email matches email pattern
            if (!groupPocEmail.match(emailPattern)) {
                alert("Please enter a valid email address");
                return false;
            }

            // Check if phone number matches phone pattern
            if (!groupPocPhone.match(phonePattern)) {
                alert("Please enter a valid phone number");
                return false;
            }
            return true;
        } else {
            alert("Please fill out all fields before registering");
            return false;
        }
    };

    // Sends a request to the backend to create a new group
    const createGroup = async () => {
        if (!assertValidGroup()) {
            return;
        }

        try {
            const groupBody = { name: groupName, pocEmail: groupPocEmail, pocPhone: groupPocPhone };
            const newGroup = await axios.post(`${process.env.REACT_APP_API_URL}/groups`, groupBody, config)

            setGroupName("");
            setGroupPocEmail("");
            setGroupPocPhone("");
            setGroups(prevGroups => [...prevGroups, newGroup.data])
        } catch (err) {
            console.log(err);
        }
    }

    // Sends a request to the backend to update a group
    const updateGroup = async (groupId) => {
        const updatedGroup = groups.find(group => group._id === groupId);

        if (updatedGroup) {
            try {
                const groupBody = { email: updatedGroup.groupPocEmail, phone: updatedGroup.groupPocPhone, paymentStatus: updatedGroup.paymentStatus};
                await axios.patch(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, groupBody, config);
            } catch (err) {
                console.log(err);
            }
        }
    }

    // Sends a request to the backend to delete a group
    const deleteGroup = async (groupId) => {
        try {
            // Check if group has already paid
            const groupToDelete = groups.find(group => group.groupId === groupId);
            if (groupToDelete.paymentStatus === "paid") {
                alert("Cannot delete a group that has already paid");
                return;
            }

            // Check if group has registrants
            const registrants = await axios.get(`${process.env.REACT_APP_API_URL}/registration/group-registrants/${groupId}`)
            if (registrants.data.length !== 0) {
                alert("Cannot delete a group that has registrants in it");
                return;
            }

            // Delete group
            await axios.delete(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, config);
            const updatedGroups = groups.filter(grp => grp.groupId !== groupId);
            setGroups(updatedGroups);
        } catch (err) {
            console.log(err);
        }
    }

	return (
		<>
			<Container>
                {/* Header */}
				<br />
				<Icon to="/admin">{year} Donut Dash Admin</Icon>
				<br />
				<SubIcon to="/">(Return to main site)</SubIcon>
				<br /><br /><br />

                <Text>Groups Management</Text>

                {/* Table with all groups */}
                <TableContainer>
					<Table>
                        <TableHeader>
                            <TableRow>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>ID</TableHeaderCell>
                            <TableHeaderCell>POC Email</TableHeaderCell>
                            <TableHeaderCell>POC Phone Number</TableHeaderCell>
                            <TableHeaderCell>Payment Status</TableHeaderCell>
                            <TableHeaderCell>Actions</TableHeaderCell>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            {/* Map through groups and create rows alphabetically */}
                            {groups
                                .sort((a, b) => a.groupName.localeCompare(b.groupName))
                                .map((group) => (
                                <TableRow key={group._id}>
                                    <TableCell>
                                        <StyledLink href={`/group-order?groupId=${group.groupId}`}>{group.groupName}</StyledLink> {/* Link to group order page */}
                                    </TableCell>

                                    <TableCell>{group.groupId}</TableCell> {/* Group ID */}

                                    <TableCell> {/* Group POC Email */}
                                        <Input
                                            value={group.groupPocEmail}
                                            onChange={(e) => {
                                                const updatedGroups = groups.map(grp => {
                                                    if (grp._id === group._id) {
                                                        return { ...grp, groupPocEmail: e.target.value };
                                                    }
                                                    return grp;
                                                });
                                                setGroups(updatedGroups);
                                            }}
                                        />
                                    </TableCell>

                                    <TableCell> {/* Group POC Phone Number */}
                                        <Input
                                            value={group.groupPocPhone}
                                            onChange={(e) => {
                                                const updatedGroups = groups.map(grp => {
                                                    if (grp._id === group._id) {
                                                        return { ...grp, groupPocPhone: e.target.value };
                                                    }
                                                    return grp;
                                                });
                                                setGroups(updatedGroups);
                                            }}
                                        />
                                    </TableCell>

                                    <TableCell> {/* Group Payment Status */}
                                        <Select
                                            value={group.paymentStatus}
                                            onChange={(e) => {
                                                const updatedGroups = groups.map(grp => {
                                                    if (grp._id === group._id) {
                                                        return { ...grp, paymentStatus: e.target.value };
                                                    }
                                                    return grp;
                                                });
                                                setGroups(updatedGroups);
                                            }}
                                        >
                                            <option value="unpaid">Unpaid</option>
                                            <option value="paid">Paid</option>
                                        </Select>
                                    </TableCell>

                                    <TableCell> {/* Save and Delete buttons */}
                                        <Button onClick={() => updateGroup(group._id)}>Save</Button>
                                        <Button onClick={() => deleteGroup(group.groupId)} style={{ marginLeft: '10px' }}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                                ))}
                        </TableBody>

					</Table>
      			</TableContainer>

                {/* New group creation form */}
                <br /><br />
                <Text>Create New Group</Text>
                <br />
                <FormContent>
                    <Text><b>Group Name</b></Text>
                    <InputWrapper>
                        <Input value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                    </InputWrapper>

                    <Text><b>Group POC Email</b></Text>
                    <InputWrapper>
                        <Input value={groupPocEmail} onChange={(e) => setGroupPocEmail(e.target.value)} />
                    </InputWrapper>

                    <Text><b>Group POC Phone Number</b></Text>
                    <InputWrapper>
                        <Input value={groupPocPhone} onChange={(e) => setGroupPocPhone(e.target.value)} />
                    </InputWrapper>
                </FormContent>

                <InputWrapper>
                    <Button onClick={createGroup}>Create Group</Button>
                </InputWrapper>

                <br /><br />

                {/* Stripe link to create a group order invoice */}
                <Text>
                    <StyledLink href="https://dashboard.stripe.com/invoices/create" target="_blank" rel="noreferrer">Create Stripe Invoice for Group Order Payment</StyledLink>
                </Text>
			</Container>
		</>
	);
}

export default GroupsElement;