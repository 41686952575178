import React from "react";
import RaceResults from "../../images/historyleft.jpg";
import DD2023 from "../../images/history2023.jpg";
import DD2022 from "../../images/history2022.jpg";
import DD2021 from "../../images/history2021.jpg";
import Photos from "../../images/historyright.jpg";
import DD2019 from "../../images/history2019.jpg";
import DD2018 from "../../images/history2018.jpg";
import DD2017 from "../../images/history2017.jpg";
import DD2016 from "../../images/history2016.jpg";
import DD2015 from "../../images/history2015.jpg";
import DD2014 from "../../images/history2014.jpg";

import {
  HistoryContainer,
  HistoryH1,
  HistoryWrapper,
  HistoryCard,
  HistoryIcon,
  HistoryH2,
  HistoryP,
} from "./HistoryElements";

const History = () => {
  return (
    <HistoryContainer id="history">
      <HistoryH1>History of Donut Dash</HistoryH1>
      <HistoryWrapper>
        {/* Race results */}
        <HistoryCard>
          <HistoryIcon src={RaceResults} />
          <HistoryH2>Looking For Your Times?</HistoryH2>
          <HistoryP>
            See how you stack up against the competition! <br />
            <a
              href="https://runsignup.com/Race/Results/52448#resultSetId-410623;perpage:100"
              rel="noreferrer"
              target="_blank"
            >
              2023 Race Results
            </a>{" "}
            <br />
            <a
              href="https://runsignup.com/Race/Results/52448#resultSetId-346306;perpage:100"
              rel="noreferrer"
              target="_blank"
            >
              2022 Race Results
            </a>{" "}
            <br />
            <a
              href="https://runsignup.com/Race/Results/52448#resultSetId-281040;perpage:100"
              rel="noreferrer"
              target="_blank"
            >
              2021 Race Results
            </a>{" "}
            <br />
            <a
              href="https://runsignup.com/Race/Results/52448#resultSetId-175489;perpage:100"
              rel="noreferrer"
              target="_blank"
            >
              2019 Race Results
            </a>{" "}
            <br />
            <a
              href="https://runsignup.com/Race/Results/52448#resultSetId-132729;perpage:100"
              rel="noreferrer"
              target="_blank"
            >
              2018 Race Results
            </a>{" "}
            <br />
            <a
              href="https://runsignup.com/Race/Results/52448#resultSetId-97177;perpage:100"
              rel="noreferrer"
              target="_blank"
            >
              2017 Race Results
            </a>
          </HistoryP>
        </HistoryCard>

        {/* 2023 */}
        <HistoryCard>
          <HistoryIcon src={DD2023} />
          <HistoryH2>2023: Speedy Donuts! </HistoryH2>
          <HistoryP>
            The 2023 iteration of Donut Dash was a great success! Participation
            reached the 800's with our competitive race breaking more and more
            records, with our first ever sub-10:30 minute race!
          </HistoryP>
        </HistoryCard>

        {/* 2022 */}
        <HistoryCard>
          <HistoryIcon src={DD2022} />
          <HistoryH2>2022: Donnie the Donut Wins Again</HistoryH2>
          <HistoryP>
            The 2022 rendition of Donut Dash was a blast! Building off our
            momentum from the previous year, we had almost 800 people sign up
            and come to Schenley Plaza for a day full of running, donuts, games,
            and fun in support of the Mario Lemieux Foundation!
          </HistoryP>
        </HistoryCard>

        {/* Photos */}
        <HistoryCard>
          <HistoryIcon src={Photos} />
          <HistoryH2>Want To Explore Some Cool Photos?</HistoryH2>
          <HistoryP>
            Check out the action from past Donut Dash races!
            <br />
            <a
              href="https://drive.google.com/drive/u/1/folders/1WVVnn4U5pocPd5hMOUw1B5XUKqv_5A95"
              rel="noreferrer"
              target="_blank"
            >
              2023 Photos
            </a>{" "}
            <br />
            <a
              href="https://cmu.box.com/s/0vklv0pgzitip1gxuunm2segiuhmq0fd"
              rel="noreferrer"
              target="_blank"
            >
              2022 Photos
            </a>{" "}
            <br />
            <a
              href="https://cmu.box.com/s/dps3kn8tmdxs5p724ad5ah0qve2kroo7"
              rel="noreferrer"
              target="_blank"
            >
              2021 Photos
            </a>{" "}
            <br />
            <a
              href="https://www.flickr.com/photos/183397121@N03/"
              rel="noreferrer"
              target="_blank"
            >
              2018 and 2019 Photos
            </a>{" "}
            <br />
            <a
              href="https://photos.google.com/share/AF1QipN3x1oz9J2lrG_sYOwE4Wu9jb9kz6F14tFgrNLwT9NxRy-1BXPhgTApFz4bnw4qAw?key=YjgxQ01QUGxPcVhEZUxFM1RpV2xoZERYdUluWDZB"
              rel="noreferrer"
              target="_blank"
            >
              2017 Photos
            </a>{" "}
            <br />
            <a
              href="https://photos.google.com/share/AF1QipODwtSdkhLgYx61zqVFAtyDUa0ayVhinzULIa7B4BKgUOVztGANHzG6vZ5WW8mTdA?key=NzFDZGd5RUtaaVZoMm96LWJwcjZoYTEzQVNtMTJ3"
              rel="noreferrer"
              target="_blank"
            >
              2016 Photos
            </a>{" "}
            <br />
            <a
              href="https://www.flickr.com/photos/136411420@N05/albums"
              rel="noreferrer"
              target="_blank"
            >
              2015 Photos
            </a>{" "}
            <br />
          </HistoryP>
        </HistoryCard>

        {/* 2021 */}
        <HistoryCard>
          <HistoryIcon src={DD2021} />
          <HistoryH2>2021: The Return of a Great Tradition</HistoryH2>
          <HistoryP>
            After a one-year hiatus due to the pandemic, Donut Dash 2021 was an
            inspiring revamp of a decade’s old CMU tradition. Over 700 runners
            joined us as we were able to raise $45,000 for the Austin’s
            Playrooms Initiative as we saw the inaugural Munchkin Mile.
          </HistoryP>
        </HistoryCard>

        {/* 2019 */}
        <HistoryCard>
          <HistoryIcon src={DD2019} />
          <HistoryH2>2019: A Perfect Day For Fun</HistoryH2>
          <HistoryP>
            With over 1,200 runners and $85,000 raised for the Austin’s
            Playrooms Initiative, Donut Dash 2019 was another smashing success!
            We were happy to continue our partnership with the Mario Lemieux
            Foundation and see the smiles on the faces of all of the
            participants.
          </HistoryP>
        </HistoryCard>

        {/* 2018 */}
        <HistoryCard>
          <HistoryIcon src={DD2018} />
          <HistoryH2>2018: The 10th Anniversary</HistoryH2>
          <HistoryP>
            Donut Dash 2018 was a huge success. We had an incredible time
            celebrating 10 years of Donut Dash! In the first year of our
            partnership with the Mario Lemieux Foundation, we were able to raise
            over $77,000 for the Austin’s Playrooms Initiative.
          </HistoryP>
        </HistoryCard>

        {/* 2017 */}
        <HistoryCard>
          <HistoryIcon src={DD2017} />
          <HistoryH2>2017: Even More Record Breaking</HistoryH2>
          <HistoryP>
            In the year of Alumni Advisor and inspiration Bob Dax's passing, we
            saw over 1,400 participants and were able to raise over $200,000 in
            his honor. With your help, we also broke our own record for the
            largest amount of money raised by an individual greek organization.
          </HistoryP>
        </HistoryCard>

        {/* 2016 */}
        <HistoryCard>
          <HistoryIcon src={DD2016} />
          <HistoryH2>2016: Record Smashing Year</HistoryH2>
          <HistoryP>
            We raised more money than ever before for the Live Like Lou Center
            for ALS Research. We completed our goal of $500,000 over five years
            raised $176,000 in honor of our hero, Alumni Advisor Bob Dax. This
            is more than any individual greek organization has ever raised
            before!
          </HistoryP>
        </HistoryCard>

        {/* 2015 */}
        <HistoryCard>
          <HistoryIcon src={DD2015} />
          <HistoryH2>2015: Move To Schenley Park</HistoryH2>
          <HistoryP>
            Donut Dash 2015 took place in Schenley Plaza in Oakland for the
            first time ever, and it was a huge success! The new route around the
            plaza and down to Phipps Conservatory was fresh and exciting. 2015
            was the first year that we featured a donut eating competition,
            which was great fun for everyone!
          </HistoryP>
        </HistoryCard>

        {/* 2014 */}
        <HistoryCard>
          <HistoryIcon src={DD2014} />
          <HistoryH2>2014: 6,600 Donuts Endless Fun</HistoryH2>
          <HistoryP>
            Centered at the Morewood Gardens parking lot on Carnegie Mellon's
            campus, featured a new route along Morewood and Forbes Avenues as
            well as live music. We made sure everyone was provided with their
            daily nutritional value of donuts (that's a thing, right?) with
            everyone six donuts heavier!
          </HistoryP>
        </HistoryCard>
      </HistoryWrapper>
    </HistoryContainer>
  );
};

export default History;
