import React from 'react';
import AdminLoginElement from '../components/Login';

const AdminLogin = () => {
    return (
        <>
            <AdminLoginElement />
        </>
    );
};

export default AdminLogin;