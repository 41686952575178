import React, { useState } from "react";
import Image from "../../images/cover.jpg";
import { Button } from "../ButtonElement";
import {
  HeroContainer,
  HeroBg,
  ImageBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <ImageBg src={Image} />
      </HeroBg>
      <HeroContent>
        <HeroH1>Donut Dash</HeroH1>
        <HeroP>Run A Mile, Eat Six Donuts, Run Another Mile!</HeroP>
        <HeroP>
          We're excited to annouce that registration has opened for 2024's Donut
          Dash!
        </HeroP>
        <HeroP>
          The race will be held at Schenley Plaza on October 5th, 2024 from
          9:00am-12:00pm
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to="/register"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Register
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
