import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';

import {
	Container,
	Icon,
    TableContainer,
	Table,
	TableRow,
	TableCell,
	TableHeaderCell,
	TableHeader,
	TableBody,
    Text,
    InputWrapper,
    Input,
    Button,
    FormContent,
    Select
} from './GroupOrderElements';

function GroupOrderElement() {

	const date = new Date();
	const year = date.getFullYear();

	const [queryParams] = useSearchParams();
	const groupId = queryParams.get("groupId");

    const [registrants, setRegistrants] = useState([]);
    const [registrantName, setRegistrantName] = useState("");
    const [registrantEmail, setRegistrantEmail] = useState("");
    const [raceType, setRaceType] = useState("");
    const [groupInfo, setGroupInfo] = useState({})

    // Fetches the data for the group and its registrants
    const fetchData = async () => {
        if (groupId !== "") {
            try {
                // Fetch that particular group's info
                const group = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}`);
                setGroupInfo(group.data[0]);
    
                // Fetch that particular group's registrants
                const groupRegistrants = await axios.get(`${process.env.REACT_APP_API_URL}/registration/group-registrants/${groupId}`);
                setRegistrants(groupRegistrants.data);
            } catch (err) {
                console.log(err);
            }
        }
    };

    // Fetch data immediately on page load if groupId is not null
    useEffect(() => {
        if (groupId != null) {
            fetchData();
        }
    }, [groupId]);

    // Asserts that the registrant has filled out all fields properly
    const assertValidRegistrant = () => {
        if (registrantName && registrantEmail && raceType) {
            if (!registrantEmail.match("[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$")) {
                alert("Please enter a valid email address");
                return false;
            }
            return true;
        } else {
            alert("Please fill out all fields before registering");
            return false;
        }
    };

    // Adds a registrant to the group
    const addGroupRegistrant = async () => {
        if (!assertValidRegistrant()) {
            return;
        }

        try {
            const groupRegistrantBody = { groupId: groupId, name: registrantName, email: registrantEmail, race: raceType };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/registration/group-registrants`, groupRegistrantBody)
            const newRegistrant = response.data;

            // Update the registrants state by adding the new registrant
            setRegistrants(prevRegistrants => [...prevRegistrants, newRegistrant]);

            setRegistrantName("");
            setRegistrantEmail("");
            setRaceType("");
        } catch (err) {
            console.log(err);
        }
    }

    // Updates a registrant's info
    const updateRegistrant = async (registrantId) => {
        const updatedRegistrant = registrants.find(registrant => registrant._id === registrantId);
        if (updatedRegistrant) {
            try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/registration/group-registrants/${registrantId}`, updatedRegistrant);
            } catch (err) {
                console.log(err);
            }
        }
    }

    // Deletes a registrant from the group
    const deleteRegistrant = async (registrantId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/registration/group-registrants/${registrantId}`);
            const updatedRegistrants = registrants.filter(reg => reg._id !== registrantId);
            setRegistrants(updatedRegistrants);
        } catch (err) {
            console.log(err);
        }
    }

    // Calculates the discount for the group
    const calculateDiscount = (numRegistrants) => {
        if (numRegistrants < 10) {
            return "0%";
        } else if (numRegistrants < 15) {
            return "20%";
        } else if (numRegistrants < 20) {
            return "30%";
        } else {
            return "40%";
        }
    }

    // Calculates the cost for the group
    const calculateCost = (numRegistrants) => {
        if (numRegistrants < 10) {
            return numRegistrants * 25;
        } else if (numRegistrants < 15) {
            return numRegistrants * 20;
        } else if (numRegistrants < 20) {
            return numRegistrants * 17.5;
        } else {
            return numRegistrants * 15;
        }
    }

	return (
		<>
			<Container>
				<br />
				<Icon to="/">{year} Donut Dash</Icon>
				<br /><br />
                <Text>{groupInfo.groupName} Group Order</Text>
                <br /><br />
                <Text>Group POC: {groupInfo.groupPocEmail} ({groupInfo.groupPocPhone})</Text>
                <Text>Payment Status: {groupInfo.paymentStatus}</Text>
                <br /><br />
                <Text>Discount Breakdown: 10+ registrants = 20% off, 15+ registrants = 30% off, 20+ registrants = 40% off</Text>
                <br /><br />
                <Text>Total Cost: ${calculateCost(registrants.length)} ({calculateDiscount(registrants.length)} off)</Text>
                <br /><br />
                <Text>Check-in time TBD on Saturday, October 5th at Schenley Plaza with races starting soon thereafter</Text>
                <br /><br />

                {/* Payment information */}
                {
                    groupInfo.paymentStatus === "unpaid" ?
                    <>
                    <Text>Payment is due on Friday, October 4th. Please reach out to info@pghdonutdash.org for questions</Text>
                    </>
                    :
                    <></>
                }

                <TableContainer>
					<Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Name</TableHeaderCell>
                                <TableHeaderCell>Email</TableHeaderCell>
                                <TableHeaderCell>Race Type</TableHeaderCell>
                                <TableHeaderCell>Actions</TableHeaderCell>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            {/* Map through the group's registrants and create rows */}
                            {registrants
                                .map((registrant) => (
                                <TableRow key={registrant._id}>

                                    <TableCell> {/* Name */}
                                        <Input
                                            value={registrant.name}
                                            onChange={(e) => {
                                                const updatedRegistrants = registrants.map(reg => {
                                                    if (reg._id === registrant._id) {
                                                        return { ...reg, name: e.target.value };
                                                    }
                                                    return reg;
                                                });
                                                setRegistrants(updatedRegistrants);
                                            }}
                                        />
                                    </TableCell>

                                    <TableCell> {/* Email */}
                                        <Input
                                            value={registrant.email}
                                            onChange={(e) => {
                                                const updatedRegistrants = registrants.map(reg => {
                                                    if (reg._id === registrant._id) {
                                                        return { ...reg, email: e.target.value };
                                                    }
                                                    return reg;
                                                });
                                                setRegistrants(updatedRegistrants);
                                            }}
                                        />
                                    </TableCell>

                                    <TableCell> {/* Race options */}
                                        <Select
                                            value={registrant.raceType}
                                            onChange={(e) => {
                                                const updatedRegistrants = registrants.map(reg => {
                                                    if (reg._id === registrant._id) {
                                                        return { ...reg, raceType: e.target.value };
                                                    }
                                                    return reg;
                                                });
                                                setRegistrants(updatedRegistrants);
                                            }}
                                        >
                                            <option value="Casual">Casual</option>
                                            <option value="Competitive">Competitive</option>
                                        </Select>
                                    </TableCell>

                                    <TableCell> {/* Actions (save and delete) */}
                                        <Button onClick={() => updateRegistrant(registrant._id)}>Save</Button>
                                        {
                                            groupInfo.paymentStatus === "unpaid" ?
                                            <>
                                                <Button onClick={() => deleteRegistrant(registrant._id)} style={{ marginLeft: '10px' }}>Delete</Button>
                                            </>
                                            :
                                            <></>
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
					</Table>
      			</TableContainer>

                <br /><br />

                {/* Add registrant form */}
                {
                    groupInfo.paymentStatus === "unpaid" ?
                    <>
                        <Text>Add yourself to the group order</Text>
                        <br />

                        <FormContent>
                            <Text><b>Name</b></Text>
                            <InputWrapper>
                                <Input value={registrantName} onChange={(e) => setRegistrantName(e.target.value)} />
                            </InputWrapper>

                            <Text><b>Email</b></Text>
                            <InputWrapper>
                                <Input value={registrantEmail} onChange={(e) => setRegistrantEmail(e.target.value)} />
                            </InputWrapper>

                            <Text><b>Race Type</b></Text>
                            <InputWrapper>
                                <Select value={raceType} onChange={(e) => setRaceType(e.target.value)} >
                                    <option value="" disabled hidden>Select an option</option>
                                    <option value="Casual">Casual</option>
                                    <option value="Competitive">Competitive</option>
                                </Select>
                            </InputWrapper>
                        </FormContent>

                        <InputWrapper>
                            <Button onClick={addGroupRegistrant}>Register Now!</Button>
                        </InputWrapper>
                    </>
                    :
                    <>
                        <Text>This group has already been paid for and is now closed</Text>
                        <br /><br />
                        <Text>In order to register, please sign up individually</Text>
                    </>
                }
			</Container>
		</>
	);
}

export default GroupOrderElement; 