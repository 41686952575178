import React from 'react';
import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Text
} from './ContactusElements';

export default function ContactUsElement() {
        
    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/">Donut Dash</Icon>
                    <FormContent>
                        <Text><b>Please email us directly at info@pghdonutdash.org</b></Text>
                    </FormContent>
                </FormWrap>
            </Container>
        </>
    );
};
