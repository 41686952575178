import React, { useState, useEffect } from "react";
import axios from 'axios';

import {
  	Container,
	Icon,
	SubIcon,
	FormWrap,
	FormContent,
	Text,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableHeaderCell,
	TableHeader,
	TableBody,
	ChangeableCell,
	Input,
	InputWrapper,
	Button,
	Select
} from './IndividualsElements';

// Get the access token from local storage
const token = localStorage.getItem('token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
};

function IndividualRegistrantsElement() {

	const date = new Date();
    const year = date.getFullYear();

	const [registrants, setRegistrants] = useState([]);
	const [registrantName, setRegistrantName] = useState([""]);
	const [registrantEmail, setRegistrantEmail] = useState([""]);
	const [registrantRaceType, setRegistrantRaceType] = useState([""]);

	// Fetches the individual registrants data from the server
	const getRegistrants = async () => {
        try {
			const allRegistrants = await axios.get(`${process.env.REACT_APP_API_URL}/registration/individual-registrants`, config);
			setRegistrants(allRegistrants.data);
		} catch (err) {
			console.log(err);
		}
    };

	// Fetches the data immediately on page load and every 3 seconds
    useEffect(() => {
        getRegistrants();

        const id = setInterval(getRegistrants, 3000);

        return () => clearInterval(id);
    }, []);

	// Toggles the "Picked Up Shirt" status of a registrant
	const togglePickedUpShirt = async (sessionId) => {
		try {
			// Update registrant data on the server
			await axios.patch(`${process.env.REACT_APP_API_URL}/registration/individual-registrants/${sessionId}/shirtpickup`, {
				hasPickedUpShirt: !registrants.find(r => r.sessionId === sessionId).pickedUpShirt
			}, config);

			// Update registrants state
			setRegistrants(prevRegistrants =>
				prevRegistrants.map(r =>
					r.sessionId === sessionId ? { ...r, pickedUpShirt: !r.pickedUpShirt } : r
				)
			);
		} catch (error) {
		  	console.error('Error toggling "Picked Up Shirt" status:', error);
		}
	};

	// Toggles the "Checked In" status of a registrant
	const toggleCheckedIn = async (sessionId) => {
		try {
			// Update registrant data on the server
			await axios.patch(`${process.env.REACT_APP_API_URL}/registration/individual-registrants/${sessionId}/checkin`, {
				hasCheckedIn: !registrants.find(r => r.sessionId === sessionId).checkedIn
			}, config);

			// Update registrants state
			setRegistrants(prevRegistrants =>
				prevRegistrants.map(r =>
					r.sessionId === sessionId ? { ...r, checkedIn: !r.checkedIn } : r
				)
			);
		} catch (error) {
		  	console.error('Error toggling "Checked In" status:', error);
		}
	};

	// Checks if the inputted registrant data is valid (used for manual registration)
	const assertValidRegistrant = () => {
        if (registrantName && registrantEmail) {
			if (registrantRaceType !== "Competitive" && registrantRaceType !== "Casual") {
				alert("Please select a race type");
				return false;
			}
            if (!registrantEmail.match("[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$")) {
                alert("Please enter a valid email address");
                return false;
            }
            return true;
        } else {
            alert("Please fill out all fields before registering");
            return false;
        }
    };

	// Sends a request to the backend to manually register a new registrant
	const manualRegistration = async () => {
		if (!assertValidRegistrant()) {
            return;
        }

        const registrantBody = { name: registrantName, email: registrantEmail, raceType: registrantRaceType };
		try {
			const newRegistrant = await axios.post(`${process.env.REACT_APP_API_URL}/registration/individual-registrants/manual`, registrantBody, config)
			setRegistrantName("");
			setRegistrantEmail("");
			setRegistrantRaceType("");
			setRegistrants(prevRegistrants => [...prevRegistrants, newRegistrant.data])
		} catch (err) {
            console.log(err);
        }
	}

	// Calculates the total number of registrations for a registrant
	const calculateRegistrationTypes = (registrations) => {
		let total = 0;
		registrations.forEach(registration => {
			switch (registration.title) {
				case 'Competitive':
					total += registration.quantity;
					break;
				case 'Casual':
					total += registration.quantity;
					break;
				case 'Kids':
					total += registration.quantity;
					break;
			}
		})
		return total;
	}

	return (
		<>
			<Container>
				{/* Header */}
				<br />
				<Icon to="/admin">{year} Donut Dash Admin</Icon>
				<br />
				<SubIcon to="/">(Return to main site)</SubIcon>

				<FormWrap>
					<FormContent>
						<Text>Individual Registrants</Text>
					</FormContent>
				</FormWrap>

				<TableContainer>
					<Table>
						<TableHeader>
							<TableRow>
							<TableHeaderCell>Name</TableHeaderCell>
							<TableHeaderCell>Quantity</TableHeaderCell>
							<TableHeaderCell>Picked Up Shirt</TableHeaderCell>
							<TableHeaderCell>Checked In</TableHeaderCell>
							</TableRow>
						</TableHeader>

						<TableBody>
							{/* Map through registrants and create rows alphabetically */}
							{registrants
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((registrant) => (
								<TableRow key={registrant._id}>
									<TableCell>{registrant.name}</TableCell> {/* registrant's name */}

									<TableCell>{calculateRegistrationTypes(registrant.registrations)}</TableCell> {/* number of registrations */}

									<ChangeableCell
										changedCell={registrant.pickedUpShirt}
										onClick={() => togglePickedUpShirt(registrant.sessionId)}
									>
										{registrant.pickedUpShirt ? 'Yes' : 'No'} {/* picked up shirt status */}
									</ChangeableCell>

									<ChangeableCell
										changedCell={registrant.checkedIn}
										onClick={() => toggleCheckedIn(registrant.sessionId)}
									>
										{registrant.checkedIn ? 'Yes' : 'No'} {/* checked in status */}
									</ChangeableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
      			</TableContainer>

				<br /><br />

				{/* Manual registration form */}
                <Text>Create New Registrant</Text>
                <br />
                <FormContent>
                    <Text><b>Name</b></Text>
                    <InputWrapper>
                        <Input value={registrantName} onChange={(e) => setRegistrantName(e.target.value)} />
                    </InputWrapper>

                    <Text><b>Email</b></Text>
                    <InputWrapper>
                        <Input value={registrantEmail} onChange={(e) => setRegistrantEmail(e.target.value)} />
                    </InputWrapper>

                    <Text><b>Race Type</b></Text>
                    <Select value={registrantRaceType} onChange={(e) => setRegistrantRaceType(e.target.value)} >
						<option value="" disabled hidden>Select an option</option>
						<option value="Casual">Casual</option>
						<option value="Competitive">Competitive</option>
					</Select>
                </FormContent>

                <InputWrapper>
                    <Button onClick={manualRegistration}>Register</Button>
                </InputWrapper>
			</Container>
		</>
	);
}

export default IndividualRegistrantsElement;