import React, { useState, useEffect } from "react";
import axios from 'axios';

import {
  	Container,
	Icon,
	SubIcon,
	FormWrap,
	FormContent,
	Text,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableHeaderCell,
	TableHeader,
	TableBody,
	ChangeableCell
} from './GroupsElements';

// Get access token from local storage
const token = localStorage.getItem('token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
};

function GroupRegistrantsElement() {

	const date = new Date();
    const year = date.getFullYear();

	const [registrants, setRegistrants] = useState([]);

	// Fetches all group registrants from the server
	const getRegistrants = async () => {
        try {
			const allRegistrants = await axios.get(`${process.env.REACT_APP_API_URL}/registration/group-registrants`, config);
			setRegistrants(allRegistrants.data);
		} catch (err) {
			console.log(err);
		}
    };

	// Fetches the data immediately on page load and every 3 seconds
    useEffect(() => {
        getRegistrants();
        const id = setInterval(getRegistrants, 3000);
        return () => clearInterval(id);
    }, []);

	// Toggles the "Picked Up Shirt" status of a registrant
	const togglePickedUpShirt = async (id) => {
		try {
			// Update registrant data on the server
			await axios.patch(`${process.env.REACT_APP_API_URL}/registration/group-registrants/${id}/shirtpickup`, {
				hasPickedUpShirt: !registrants.find(r => r._id === id).pickedUpShirt
			}, config);

			// Update registrants state
			setRegistrants(prevRegistrants =>
				prevRegistrants.map(r =>
					r._id === id ? { ...r, pickedUpShirt: !r.pickedUpShirt } : r
				)
			);
		} catch (error) {
		  	console.error('Error toggling "Picked Up Shirt" status:', error);
		}
	};

	// Toggles the "Checked In" status of a registrant
	const toggleCheckedIn = async (id) => {
		try {
			// Update registrant data on the server
			await axios.patch(`${process.env.REACT_APP_API_URL}/registration/group-registrants/${id}/checkin`, {
				hasCheckedIn: !registrants.find(r => r._id === id).checkedIn
			}, config);

			// Update registrants state
			setRegistrants(prevRegistrants =>
				prevRegistrants.map(r =>
					r._id === id ? { ...r, checkedIn: !r.checkedIn } : r
				)
			);
		} catch (error) {
		  	console.error('Error toggling "Checked In" status:', error);
		}
	};

	return (
		<>
			<Container>
				{/* Header */}
				<br />
				<Icon to="/admin">{year} Donut Dash Admin</Icon>
				<br />
				<SubIcon to="/">(Return to main site)</SubIcon>

				<FormWrap>
					<FormContent>
						<Text>Group Registrants</Text>
					</FormContent>
				</FormWrap>

				<TableContainer>
					<Table>
					<TableHeader>
						<TableRow>
						<TableHeaderCell>Name</TableHeaderCell>
						<TableHeaderCell>Race Type</TableHeaderCell>
						<TableHeaderCell>Picked Up Shirt</TableHeaderCell>
						<TableHeaderCell>Checked In</TableHeaderCell>
						</TableRow>
					</TableHeader>

					<TableBody>
						{/* Map through registrants and create rows alphabetically */}
						{registrants
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((registrant) => (
							<TableRow key={registrant._id}>
								<TableCell>{registrant.name}</TableCell>
								<TableCell>{registrant.raceType}</TableCell>

								<ChangeableCell
									changedCell={registrant.pickedUpShirt}
									onClick={() => togglePickedUpShirt(registrant._id)}
								>
									{registrant.pickedUpShirt ? 'Yes' : 'No'} {/* Picked up shirt status */}
								</ChangeableCell>
								<ChangeableCell
									changedCell={registrant.checkedIn}
									onClick={() => toggleCheckedIn(registrant._id)}
								>
									{registrant.checkedIn ? 'Yes' : 'No'} {/* Checked in status */}
								</ChangeableCell>
							</TableRow>
							))}
						</TableBody>

					</Table>
      			</TableContainer>
			</Container>
		</>
	);
}

export default GroupRegistrantsElement;