import React from 'react';
import CancelElement from '../components/Cancel';

const Cancel = () => {
    return (
        <>
            <CancelElement />
        </>
    );
};

export default Cancel;