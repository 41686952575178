import React, { useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';

import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Text,
    Label,
    Input,
    Button,
    InputWrapper,
    CheckboxWrapper,
    CheckboxInput,
    StyledLink
} from './RegisterElements';

export default function RegisterElement() {

    const date = new Date();
    const year = date.getFullYear();

    const [isLoading, setIsLoading] = useState(false);

    // Competitive race info for Stripe
    const [competitiveRace, setCompetitiveRace] = useState({
        title: "Competitive",
        productId: "price_1N6MGRLbeTnLwF6dArhbNrDN",
        price: 2500,
        quantity: 0
    });

    // Casual race info for Stripe
    const [casualRace, setCasualRace] = useState({
        title: "Casual",
        productId: "price_1N6MHQLbeTnLwF6dP05zeYGJ",
        price: 2500,
        quantity: 0
    });

    // Kids race info for Stripe
    const [kidsRace, setKidsRace] = useState({
        title: "Kids",
        productId: "price_1N6MIKLbeTnLwF6dBOVd6QaH",
        price: 2000,
        quantity: 0
    });

    const [processingFee, setProcessingFee] = useState(true)

    // Send a request to the backend to create a Stripe checkout session
    const makePayment = async () => {
        // Make sure that at least one race is selected
        if (competitiveRace.quantity === 0 &&
            casualRace.quantity === 0 &&
            kidsRace.quantity === 0) {
                return;
        }

        setIsLoading(true);

        // Create a Stripe checkout session
        const stripe = await loadStripe("pk_live_51N3ImjLbeTnLwF6defwqS969xi3KKChBm8JnRKxmJO4fMM1xvtfySKxoplhsEgbDqZuEjfoHBtvxpFUmJkmVwR2700vBin4OTL");
        const createCheckoutSessionBody = { cart: [competitiveRace, casualRace, kidsRace], customerFee: processingFee };

        // Send a request to the backend to create a Stripe checkout session
        axios.post(`${process.env.REACT_APP_API_URL}/registration/individual-registrants`, createCheckoutSessionBody)
          .then(res => {
            const session = res.data;

            // Redirect to the Stripe checkout page on success
            const result = stripe.redirectToCheckout({
                sessionId: session.sessionId
            });

            if (result.error) {
                console.log(result.error);
            }
            setIsLoading(false);
        }).catch((error) => {
            console.error("Error while processing payment:", error);
            setIsLoading(false);
        });
    }
    
    // Increment logic for number of competitive races selected
    function incrementCompetitive() {
        if (competitiveRace.quantity < 100) {
            setCompetitiveRace({...competitiveRace,
                quantity: competitiveRace.quantity + 1
            });
        }
    }

    // Decrement logic for number of competitive races selected
    function decrementCompetitive() {
        if (competitiveRace.quantity > 0) {
            setCompetitiveRace({...competitiveRace,
                quantity: competitiveRace.quantity - 1
            });
        }
    }

    // Typing logic for number of competitive races selected
    function changeCompetitive(e) {
        const x = Number(e.target.value);
        if (!isNaN(x) && x >= 0 && x < 100) {
            setCompetitiveRace({...competitiveRace,
                quantity: x
            });
        }
    }

    // Increment logic for number of casual races selected
    function incrementCasual() {
        if (casualRace.quantity < 100) {
            setCasualRace({...casualRace,
                quantity: casualRace.quantity + 1
            });
        }
    }

    // Decrement logic for number of casual races selected
    function decrementCasual() {
        if (casualRace.quantity > 0) {
            setCasualRace({...casualRace,
                quantity: casualRace.quantity - 1
            });
        }
    }

    // Typing logic for number of casual races selected
    function changeCasual(e) {
        const x = Number(e.target.value);
        if (!isNaN(x) && x >= 0 && x < 100) {
            setCasualRace({...casualRace,
                quantity: x
            });
        }
    }

    // Increment logic for number of kids races selected
    function incrementKids() {
        if (kidsRace.quantity < 100) {
            setKidsRace({...kidsRace,
                quantity: kidsRace.quantity + 1
            });
        }
    }

    // Decrement logic for number of kids races selected
    function decrementKids() {
        if (kidsRace.quantity > 0) {
            setKidsRace({...kidsRace,
                quantity: kidsRace.quantity - 1
            });
        }
    }

    // Typing logic for number of kids races selected
    function changeKids(e) {
        const x = Number(e.target.value);
        if (!isNaN(x) && x >= 0 && x < 100) {
            setKidsRace({...kidsRace,
                quantity: x
            });
        }
    }

    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/">{year} Donut Dash</Icon>

                    {/*  */}
                    {/*  */}
                    {/*  */}
                    {/* Uncomment for when registration is open and comment out when registration is closed */}
                    {/*  */}
                    {/*  */}
                    {/*  */}

                    <Text>Register for the 15th annual Donut Dash!</Text>
                    <Text>The event will take place on Saturday, October 5th 2024 at Schenley Plaza</Text>

                    {/* <Text>Thank you so much for making the 15th annual Donut Dash a huge success!</Text>
                    <Text>We hope to see you again next year!</Text> */}
                    <br />
                    <FormContent>
                        <Text><b>Competitive Race</b></Text>
                        <InputWrapper>
                            <Button onClick={decrementCompetitive}>-</Button>
                            <Input min="0" value={competitiveRace.quantity} onChange={changeCompetitive} />
                            <Button onClick={incrementCompetitive}>+</Button>
                        </InputWrapper>

                        <Text><b>Casual Race</b></Text>
                        <InputWrapper>
                            <Button onClick={decrementCasual}>-</Button>
                            <Input min="0" value={casualRace.quantity} onChange={changeCasual} />
                            <Button onClick={incrementCasual}>+</Button>
                        </InputWrapper>

                        <Text><b>Kids Race</b></Text>
                        <InputWrapper>
                            <Button onClick={decrementKids}>-</Button>
                            <Input min="0" value={kidsRace.quantity} onChange={changeKids} />
                            <Button onClick={incrementKids}>+</Button>
                        </InputWrapper>

                        <br />
                        <Text>Total: ${(competitiveRace.quantity * 25) + (casualRace.quantity * 25) + (kidsRace.quantity * 20)}</Text>

                        <CheckboxWrapper>
                            <CheckboxInput
                                type="checkbox"
                                onChange={(e) => setProcessingFee(e.currentTarget.checked)}
                                checked={processingFee}
                            />
                            <Label><i>I'd like to help cover the fees</i></Label>
                        </CheckboxWrapper>
                        <br />

                        <InputWrapper>
                            <Button onClick={makePayment}>
                                {isLoading ? "Loading..." : "Register Now"}
                            </Button>
                        </InputWrapper>
                    </FormContent>

                    <br />
                    <Text><StyledLink href='//donate.stripe.com/6oEaH847dgap8hifYY' target="_blank" rel="noreferrer">Make a Donation</StyledLink></Text>
                </FormWrap>
            </Container>
        </>
    );
};
