import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    /* min-height: 692px; */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: auto;
    background: linear-gradient(
        180deg,
        #70c9ff,
        #ff8097
    );
`

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 400px) {
        height: 80%;
        width: 80%;
    }
`

export const Icon = styled(Link)`
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    @media screen and (max-width: 480px) {
        margin-left: 16px;
        margin-top: 8px;
        width: 113%;
    }
`

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 480px) {
        padding: 10px;
    }
`

export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 40px;
`

export const SubText = styled.span`
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 20px;
`

export const StyledLink = styled.a`
    color: #fff;
`;
