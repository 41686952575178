import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		z-index: 0;
		overflow: auto;
		background: linear-gradient(
				180deg,
				#70c9ff,
				#ff8097
		);
		text-align: center;
`

export const Icon = styled(Link)`
		text-decoration: none;
		color: #fff;
		text-align: center;
		font-weight: 700;
		font-size: 32px;
`

export const TableContainer = styled.div`
	margin-top: 20px;
	overflow-x: auto;
`;

export const Table = styled.table`
	width: 80%;
	border-collapse: collapse;
	margin-left: auto;
	margin-right: auto;
	color: #fff;
`;

export const TableHeader = styled.thead`
	background-color: #007bff;
`;

export const TableRow = styled.tr`
	border-bottom: solid #fff;
	font-weight: bold;
`;

export const TableHeaderCell = styled.th`
	padding: 10px;
	text-align: center;
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td`
	padding: 10px;
	text-align: center;
`;

export const Input = styled.input`
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
    margin-left: 5px;
    margin-right: 5px;
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FormContent = styled.div`
    display: flex;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
    justify-content: center;
`

export const Button = styled.button`
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
`;

export const Text = styled.span`
	text-align: center;
	margin: 10px;
	color: #fff;
	font-size: 20px;
`

export const Select = styled.select`
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
    margin-left: 5px;
    margin-right: 5px;
	margin-bottom: 10px;
	margin-top: 10px;
`;
