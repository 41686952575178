import React from "react";
import SAE from "../../images/sae.jpg";
import CMU from "../../images/cmu.png";
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
  FooterNote, // Assuming you have a styled component for the note
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo src={SAE} />
            <SocialIcons>
              <SocialIconLink
                href="//www.facebook.com/PghDonutDash"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="//www.instagram.com/pghdonutdash/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="//www.youtube.com/channel/UC7UWwgKtgv-ubAHG0Edj5KA"
                target="_blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href="//twitter.com/PGHDonutDash"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
            </SocialIcons>
            <SocialLogo src={CMU} />
          </SocialMediaWrap>
        </SocialMedia>
        <FooterNote>
          PGH Donut Dash has been recognized by the Internal Revenue Service as
          a non-profit public charity under Section 501(c)(3) of the Internal
          Revenue Code. Contributions to PGH Donut Dash are tax-deductible to
          the extent permitted by law. Federal Identification Number is
          92-1797336.
        </FooterNote>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
