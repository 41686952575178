import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: auto;
    background: linear-gradient(
        180deg,
        #70c9ff,
        #ff8097
    );
    text-align: center;
`

export const Icon = styled(Link)`
    // margin-top: 32px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
`

export const Text = styled.span`
    text-align: center;
    margin: 10px;
    color: #fff;
    font-size: 20px;
`

export const StyledLink = styled.a`
    color: #fff;
`;