import React from 'react';
import DonateElement from '../components/Donate';

const Donate = () => {
    return (
        <>
            <DonateElement />
        </>
    );
};

export default Donate;