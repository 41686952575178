import React from 'react';
import GroupRegistrantsElement from '../components/Admin/GroupRegistrants';
import AuthWrapper from '../components/AuthWrapper';

const GroupRegistrants = () => {
    return (
        <>
            <GroupRegistrantsElement />
        </>
    );
};

export default AuthWrapper(GroupRegistrants);