import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthWrapper = (WrappedComponent) => {
    const ComponentWithAuthentication = (props) => {
        const navigate = useNavigate();

        // Check if the token is valid
        const isValid = async (token) => {
            try {
                // Send a request to the backend to verify the token
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/admin/verify`, {
                    params: {
                        token: token
                    }
                });

                if (!response.data.valid) {
                    return false;
                }
                return true;
            } catch (error) {
                return false;
            }
        }

        // Check if the user is authenticated
        const isAuthenticated = async () => {
            const token = localStorage.getItem('token');

            // If the token exists, check if it is valid and hasn't expired
            if (token) {
                const { exp } = JSON.parse(atob(token.split('.')[1]));
                const isValidToken = await isValid(token);

                return exp && Date.now() < exp * 1000 && isValidToken;
            }
        
            return false;
        };

        // Check if the token is valid
        const checkTokenValidity = async () => {
            const authenticated = await isAuthenticated();

            if (!authenticated) {
                navigate('/login');
            }
        };

        useEffect(() => {
            // Check token validity immediately on mount
            checkTokenValidity();

            // Set up the interval to check token validity every ten seconds (10000ms)
            const tokenCheckInterval = setInterval(checkTokenValidity, 10000);

            // Clean up the interval on component unmount to avoid memory leaks
            return () => clearInterval(tokenCheckInterval);
        });

        // If the user is authenticated, render the wrapped component
        return <WrappedComponent {...props} />;
    };

    return ComponentWithAuthentication;
};

export default AuthWrapper;
