import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: auto;
    background: linear-gradient(
        180deg,
        #70c9ff,
        #ff8097
    );
`

export const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Icon = styled(Link)`
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
`

export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Text = styled.span`
    text-align: center;
    margin: 10px;
    color: #fff;
    font-size: 20px;
`

export const Label = styled.label`
    text-align: center;
    color: #fff;
    font-size: 20px;
    align-items: center;
`

export const Button = styled.button`
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
`;

export const Input = styled.input`
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 50px;
    margin-left: 5px;
    margin-right: 5px;
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckboxWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CheckboxInput = styled.input`
  /* Add your checkbox styles here */
    margin-right: 10px; /* Add spacing between the checkbox and the label */
    display: flex;
`;

export const StyledLink = styled.a`
    color: #fff;
`;
