import React from 'react';
import IndividualRegistrantsElement from '../components/Admin/IndividualRegistrants';
import AuthWrapper from '../components/AuthWrapper';

const IndividualRegistrants = () => {
    return (
        <>
            <IndividualRegistrantsElement />
        </>
    );
};

export default AuthWrapper(IndividualRegistrants);