import React from 'react';
import GroupOrderElement from '../components/GroupOrder';

const GroupOrder = () => {
    return (
        <>
            <GroupOrderElement />
        </>
    );
};

export default GroupOrder;