import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Label,
    Input,
    Button,
	Text,
	Error
} from './LoginElements';

const LoginPage = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();

	const date = new Date();
    const year = date.getFullYear();

	// Send a request to the backend to login
	const handleLogin = async () => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/admin/login`, {
				username,
				password,
			});
			const token = response.data.token;

			// Save the token in local storage for subsequent authenticated requests
			localStorage.setItem('token', token);

			// Redirect to the admin page after successful login
			navigate('/admin');
		} catch (error) {
			console.error('Login failed:', error.response?.data?.message || 'Unknown error');
			setError('Invalid credentials');
		}
	};

	// Handle pressing enter to login
	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleLogin();
		}
	};

	return (
		<Container>
			<FormWrap>
			<Icon to="/">{year} Donut Dash</Icon>

			<FormContent>
				<Text>Admin Login</Text>
				<Label htmlFor="username">Username</Label>
				<Input
					type="text"
					id="username"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					onKeyPress={handleKeyPress}
				/>

				<Label htmlFor="password">Password</Label>
				<Input
					type="password"
					id="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyPress={handleKeyPress}
				/>

				<Button onClick={handleLogin}>Login</Button>
				{error && <Error>{error}</Error>}
			</FormContent>
			</FormWrap>
		</Container>
	);
};

export default LoginPage;
