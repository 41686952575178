import React from "react";

import {
  	Container,
	Icon,
	Text,
	StyledLink
} from './CancelElements';

function CancelElement() {

	const date = new Date();
    const year = date.getFullYear();

	return (
		<>
			<Container>
				<br />
				<Icon to="/">{year} Donut Dash</Icon>
				<br /><br />
				<Text>Oops! Your payment has been cancelled</Text>
				<br /><br />
				<Text>If you have any questions, please email us at <StyledLink href="mailto:info@pghdonutdash.org">info@pghdonutdash.org</StyledLink></Text>
				<br /><br />
				<Text>
					<StyledLink href="/register">Register Here</StyledLink> or <StyledLink href='//donate.stripe.com/6oEaH847dgap8hifYY' target="_blank" rel="noreferrer">Make a Donation</StyledLink>
				</Text>
			</Container>
		</>
	);
}

export default CancelElement;