import React from 'react';
import SuccessElement from '../components/Success';

const Success = () => {
    return (
        <>
            <SuccessElement />
        </>
    );
};

export default Success;