import React from 'react';
import ContactUsElement from '../components/ContactUs';

const ContactUs = () => {
    return (
        <>
            <ContactUsElement />
        </>
    );
};

export default ContactUs;