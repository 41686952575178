import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';

import {
	Container,
	Icon,
	Text,
	StyledLink
} from './SuccessElements';

function SuccessElement() {

	const date = new Date();
	const year = date.getFullYear();

	const [queryParams] = useSearchParams();
	const sessionId = queryParams.get("sessionId");

	const [name, setName] = useState("");
	const [competitive, setCompetitive] = useState(0);
	const [casual, setCasual] = useState(0);
	const [kids, setKids] = useState(0);

	// Send a request to the backend to update the payment status of a registration
	const updatePayment = async function () {
		try {
			await axios.patch(`${process.env.REACT_APP_API_URL}/registration/individual-registrants/${sessionId}/confirm`);
		} catch (err) {
			console.log(err);
		}
	}

	// Send a request to the backend to get the registration information
	const getRegistration = async function () {
		try {
			const registrant = await axios.get(`${process.env.REACT_APP_API_URL}/registration/individual-registrants/${sessionId}`);
			setName(registrant.data.name);

			// Calculate number of registrations for each race type
			registrant.data.registrations.forEach(registration => {
				switch (registration.title) {
					case 'Competitive':
						setCompetitive(registration.quantity);
						break;
					case 'Casual':
						setCasual(registration.quantity);
						break;
					case 'Kids':
						setKids(registration.quantity);
						break;
				}
			});
		} catch (err) {
			console.log(err);
		}
	}

	// Update payment status and get registration information on page load
	useEffect(() => {
		if (sessionId != null) {
			updatePayment();
			getRegistration();
		}
	}, []);

	return (
		<>
			<Container>
				<br />
				<Icon to="/">{year} Donut Dash</Icon>
				<br /><br />

				{/* Success page and error page handling */}
				{
					sessionId == null ?
					<>
						<Text>No order was found</Text>
						<br /><br />
						<Text>If you believe this was a mistake please contact us at <StyledLink href="mailto:info@pghdonutdash.org">info@pghdonutdash.org</StyledLink></Text>
						<br /><br />
						<Text><StyledLink href="/register">Register Here</StyledLink> or <StyledLink href='//donate.stripe.com/6oEaH847dgap8hifYY' target="_blank" rel="noreferrer">Make a Donation</StyledLink></Text>
					</>
					:
					<>
						<Text>Hi {name}! Thank you for registering for Donut Dash!</Text>
						<br /><br />
						<Text>Your signups: Casual - {casual}, Competitive: {competitive}, Kids: {kids}</Text>
						<br /><br />
						<Text>See you at the race on Saturday, October 5th at Schenley Plaza!</Text>
						<br /><br />
						<Text>Check-in time TBD on Saturday, October 5th at Schenley Plaza with races starting soon thereafter</Text>
						<br /><br />
						<Text><StyledLink href='//donate.stripe.com/6oEaH847dgap8hifYY' target="_blank" rel="noreferrer">Make a Donation</StyledLink></Text>
					</>
				}
			</Container>
		</>
	);
}

export default SuccessElement; 