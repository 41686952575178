import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 0;
	overflow: auto;
	background: linear-gradient(
			180deg,
			#70c9ff,
			#ff8097
	);
	text-align: center;
`

export const Icon = styled(Link)`
	text-decoration: none;
	color: #fff;
	text-align: center;
	font-weight: 700;
	font-size: 32px;
`

export const SubIcon = styled(Link)`
	text-decoration: none;
	color: #fff;
	text-align: center;
	font-size: 16px;
`

export const ButtonLink = styled(Link)`
    text-decoration: none;
    display: inline-block;
    margin-right: 20px;
`;

export const Button = styled.button`
    font-size: 18px;
    padding: 10px 20px;
    background: none;
    border: 2px solid white;
    color: white;
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Iframe = styled.iframe`
	background: #000000;
	border: none;
	border-radius: 2px;
	margin: 5px;
	box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);
`
