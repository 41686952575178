import React from 'react';
import GroupsElement from '../components/Admin/Groups';
import AuthWrapper from '../components/AuthWrapper';

const Groups = () => {
    return (
        <>
            <GroupsElement />
        </>
    );
};

export default AuthWrapper(Groups);