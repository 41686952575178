import React from 'react';
import AdminElement from '../components/Admin';
import AuthWrapper from '../components/AuthWrapper';

const Admin = () => {
    return (
        <>
            <AdminElement />
        </>
    );
};

export default AuthWrapper(Admin);