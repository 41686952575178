import React from "react";
import axios from 'axios';
import {
	Container,
	Icon,
	SubIcon,
	ButtonContainer,
	ButtonLink,
	Button,
    Iframe
} from './AdminElements';

// Get access token from local storage
const token = localStorage.getItem('token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
};

function AdminElement() {

    const date = new Date();
    const year = date.getFullYear();

    // Sends a request to the backend to backup the registrant data
    const backupRegistrantData = async () => {
        try {
            alert("Backing up registrant data...");
            await axios.post(`${process.env.REACT_APP_API_URL}/tools/backup`, config);
            alert("Backup successful")
        } catch (err) {
            console.log(err);
            alert("Backup failed")
        }
    }

    return (
        <>
            <Container>
                {/* Header */}
                <br />
                <Icon to="/admin">{year} Donut Dash Admin</Icon>
                <br />
                <SubIcon to="/">(Return to main site)</SubIcon>
                <br /><br /><br /><br />

                {/* Navigation */}
				<ButtonContainer>
                    <ButtonLink to="/admin/individual-registrants">
                        <Button>Individual Registrants</Button>
                    </ButtonLink>
                    <ButtonLink to="/admin/group-registrants">
                        <Button>Group Registrants</Button>
                    </ButtonLink>
                    <ButtonLink to="/admin/groups">
                        <Button>Manage Groups</Button>
                    </ButtonLink>
                </ButtonContainer>

                <br /><br />

                {/* MongoDB Charts w/ registration stats */}
                <Iframe width="400" height="300" src="https://charts.mongodb.com/charts-project-0-nibep/embed/charts?id=64f9e00e-046c-44a4-851f-13a89c5f4416&maxDataAge=120&theme=dark&autoRefresh=true"></Iframe>
                <Iframe width="400" height="300" src="https://charts.mongodb.com/charts-project-0-nibep/embed/charts?id=64f9e063-5572-489a-8f9a-59fcef56b599&maxDataAge=120&theme=dark&autoRefresh=true"></Iframe>
                <Iframe width="400" height="300" src="https://charts.mongodb.com/charts-project-0-nibep/embed/charts?id=64f9e095-86be-4657-870e-b28b808e0bbd&maxDataAge=120&theme=dark&autoRefresh=true"></Iframe>

                <br /><br />
				<Button onClick={backupRegistrantData}>Backup registrant data</Button>
            </Container>
        </>
    );
}

export default AdminElement;
