import React from 'react';
import RegisterElement from '../components/Register';

const Register = () => {
    return (
        <>
            <RegisterElement />
        </>
    );
};

export default Register;