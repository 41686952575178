import React from 'react';
import Home from './pages/home';
import ContactUs from './pages/contactus';
import Donate from './pages/donate';
import Register from './pages/register';
import Success from './pages/success';
import Cancel from './pages/cancel';
import GroupOrder from './pages/groupOrder';
import Login from './pages/login';
import Admin from './pages/admin';
import IndividualRegistrants from './pages/individualRegistrants';
import GroupRegistrants from './pages/groupRegistrants';
import Groups from './pages/groups';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

	return (
		<Router>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/contactus' element={<ContactUs />} />
				<Route path='/register' element={<Register />} />
				<Route path='/donate' element={<Donate />} />
				<Route path='/success' element={<Success />} />
				<Route path='/cancel' element={<Cancel />} />
				<Route path='/group-order' element={<GroupOrder />} />
				<Route path='/login' element={<Login />} />
				<Route path='/admin' element={<Admin />} />
				<Route path='/admin/individual-registrants' element={<IndividualRegistrants />} />
				<Route path='/admin/group-registrants' element={<GroupRegistrants />} />
				<Route path='/admin/groups' element={<Groups />} />
			</Routes>
		</Router>
	);
}

export default App;